class Hubs {
  static SupportedRegions = {
    ITALY: "IT",
    GERMANY: "DE",
    FRANCE: "FR",
  };

  static hubs = [
    {
      name: "Genova",
      coordinates: { lat: 44.4056, lng: 8.9463 },
      country: this.SupportedRegions.ITALY,
    },
    {
      name: "Rome",
      coordinates: { lat: 41.9028, lng: 12.4964 },
      country: this.SupportedRegions.ITALY,
    },
    {
      name: "Monolopoli",
      coordinates: { lat: 40.9525, lng: 17.2986 },
      country: this.SupportedRegions.ITALY,
    },
    {
      name: "Hamburg",
      coordinates: { lat: 53.5488, lng: 9.9872 },
      country: this.SupportedRegions.GERMANY,
    },
    {
      name: "Berlin",
      coordinates: { lat: 52.52, lng: 13.405 },
      country: this.SupportedRegions.GERMANY,
    },
    {
      name: "Munich",
      coordinates: { lat: 48.1351, lng: 11.582 },
      country: this.SupportedRegions.GERMANY,
    },
    {
      name: "Paris",
      coordinates: { lat: 48.8566, lng: 2.3522 },
      country: this.SupportedRegions.FRANCE,
    },
    {
      name: "Nice",
      coordinates: { lat: 43.7102, lng: 7.262 },
      country: this.SupportedRegions.FRANCE,
    },
  ];
}

class MapHelper {
  constructor(map) {
    this.markers = [];
    this.map = map;
  }

  // Adds a marker to the map and push to the array.
  addMarker(position) {
    let map = this.map;
    const marker = new google.maps.Marker({
      position,
      map,
    });

    marker.setMap(map);
    this.markers.push(marker);
  }

  // Sets the map on all markers in the array.
  setMapOnAll(obj) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(obj);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  hideMarkers() {
    this.setMapOnAll(null);
  }

  // Shows any markers currently in the array.
  showMarkers() {
    let map = this.map;
    this.setMapOnAll(map);
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers() {
    this.hideMarkers();
    this.markers = [];
  }

  async getDistance(pointA, pointB, successCallback, failureCallback) {
    let directionsService = new google.maps.DirectionsService();
    // Create route from existing points used for markers
    const route = {
      origin: pointA,
      destination: pointB,
      travelMode: "DRIVING",
    };

    directionsService.route(route, function (response, status) {
      // anonymous function to capture directions
      if (status !== "OK") {
        // failureCallback('Directions request failed due to ' + status)
        successCallback(-1);
      } else {
        var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
          // failureCallback('Directions request failed');
          successCallback(-1);
        } else {
          // let duration = directionsData.duration.text;
          let distanceInKm = directionsData.distance.value;

          successCallback(distanceInKm, response);
        }
      }
    });
  }

  drawDirection(selectedPlace, hub) {
    this.deleteMarkers();
    let directionsRenderer = new google.maps.DirectionsRenderer();

    let self = this;
    this.getDistance(
      selectedPlace,
      hub,
      function (distance, response) {
        directionsRenderer.setDirections(response); // Add route to the map
        directionsRenderer.setMap(self.map); // Existing map object displays directions
        self.markers.push(directionsRenderer);
      },
      function (message) {
        alert(message);
      }
    );
  }

  async getCountryFromFormattedAddress(formatted_address) {
    const geocoder = new google.maps.Geocoder();

    //get the country shortname
    let futureCountryShortForm = new Promise(function (resolve, reject) {
      geocoder.geocode(
        {
          address: formatted_address,
        },
        (raw) => {
          const countries = [
            ...new Set(
              raw.map(
                (c) =>
                  c.address_components.filter((a) => {
                    return a.types.includes("country");
                  })[0].short_name
              )
            ),
          ];

          if (countries.length !== 0) {
            resolve(countries[0]);
          }
        }
      );
    });

    return futureCountryShortForm;
  }

  async getAddressFromCoordinates(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    const geocoder = new google.maps.Geocoder();

    let futureCurrentPosition = new Promise(function (resolve, reject) {
      geocoder.geocode(
        {
          latLng: latlng,
        },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              resolve(results[1]);
            } else {
              reject("No results found");
            }
          } else {
            reject("Geocoder failed due to: " + status);
          }
        }
      );
    });

    return futureCurrentPosition;
  }
}

async function getNearestHub(map, selectedPlace, mapHelper) {
  const address = selectedPlace.formatted_address;
  let selectedCountry = await mapHelper.getCountryFromFormattedAddress(address);
  let selectedCoordinates = {
    lat: selectedPlace.geometry.location.lat(),
    lng: selectedPlace.geometry.location.lng(),
  };

  //get hubs in same region
  let countryHubs = Hubs.hubs.filter(function (item) {
    return item.country === selectedCountry;
  });

  if (countryHubs.length === 0) {
    alert("Service not available in this country");
    return;
  }

  //get distance foreach hub to point with gmaps requests
  let promisesArray = [];
  for (const hub of countryHubs) {
    let futureValue = new Promise(function (resolve, reject) {
      mapHelper.getDistance(
        selectedCoordinates,
        hub.coordinates,
        function (response) {
          resolve({ hub: hub, distance: response });
        }
      );
    });

    promisesArray.push(futureValue);
  }

  //when distances are collected
  let allPromise = Promise.all(promisesArray);
  try {
    let distances = await allPromise;
    console.log("Calculated distances: ", distances);
    distances = distances.filter(function (item) {
      return item.distance >= 0;
    });

    if (distances.length === 0) {
      alert("No Way FOUND");
    }
    let minDistance = distances.reduce(function (prev, curr) {
      return prev["distance"] < curr["distance"] ? prev : curr;
    });

    mapHelper.drawDirection(selectedCoordinates, minDistance.hub.coordinates);
  } catch (error) {
    console.log(error); // rejectReason of any first rejected promise
  }
}

/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
// This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
window.initMap = initMap;

function initMap() {
  const map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: 45.6159, lng: 9.3684 },
    zoom: 9,
    disableDefaultUI: true,
  });
  let mapHelper = new MapHelper(map);

  const card = document.getElementById("pac-card");
  const input = document.getElementById("pac-input");
  const biasInputElement = document.getElementById("use-location-bias");
  const strictBoundsInputElement = document.getElementById("use-strict-bounds");
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    types: [], // [], ["address"], ["establishment"], ["(cities)"], ["(regions)"]
  };

  const autocomplete = new google.maps.places.Autocomplete(input, options);

  // Bind the map's bounds (viewport) property to the autocomplete object,
  // so that the autocomplete requests use the current map bounds for the
  // bounds option in the request.
  autocomplete.bindTo("bounds", map);

  autocomplete.addListener("place_changed", async () => {
    const selectedPlace = autocomplete.getPlace();

    if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert(
        "No details available for input: '" + selectedPlace.name + "'"
      );
      return;
    }

    //Calculate distances
    await getNearestHub(map, selectedPlace, mapHelper);
  });

  let getGpsBtn = document.querySelector("#get-gps");
  getGpsBtn.addEventListener("click", async function () {
    if ("geolocation" in navigator) {
      //dummy data
      let currentPlace = await mapHelper.getAddressFromCoordinates(
        "45.600445",
        "9.361138"
      );
      await getNearestHub(map, currentPlace, mapHelper);
      return;

      //replace with actual current position
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          let coordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          let currentPlace = await mapHelper.getAddressFromCoordinates(
            coordinates.lat,
            coordinates.lng
          );
          await getNearestHub(map, currentPlace, mapHelper);
        },
        function (error) {
          console.log("error: ", error);
        },
        { timeout: 10000 }
      );
    } else {
      /* geolocation IS NOT available */
      alert("geolocation IS NOT available");
    }
  });
}
