export function removeBtnDisable(target) {
  function checkHasValue(e, arr) {
    if (e.target.value || e.target.checked) {
      if (!arr.includes(e.target.id)) {
        arr.push(e.target.id);
      }
    } else if (e.target.value == "" || !e.target.checked) {
      if (arr.includes(e.target.id)) {
        let index = arr.indexOf(e.target.id);
        arr.splice(index, 1);
      }
    }

    return arr.length;
  }

  let allHasValue = [];

  let inputs = target.querySelectorAll("input[required]");
  let buttons = target.querySelectorAll(".btn");

  ["keyup", "click"].forEach((evt) => {
    inputs.forEach((input) => {
      input.addEventListener(
        evt,
        (e) => {
          let filledFieldsNumber = checkHasValue(e, allHasValue);
          if (filledFieldsNumber == inputs.length) {
            buttons.forEach((btn) => {
              btn.disabled = false;
            });
          } else {
            buttons.forEach((btn) => {
              btn.disabled = true;
            });
          }
        },
        false
      );
    });
  });
}
